<template>
  <ErrorPage>
    <v-container class="pb-0">
      <v-row justify="center" class="error-code pt-4">
        <v-col cols="12" class="pa-0">
          {{ $t('exception.page_not_found') }}
        </v-col>
      </v-row>
      <v-row class="no-gutters" justify="space-around">
        <v-col cols="auto">
          <v-row no-gutters justify="center">
            <p class="mb-2 error-text">
              {{ $t('exception.not_found_description') }}
            </p>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </ErrorPage>
</template>

<script>
import ErrorPage from './ErrorPage';

export default {
  name: 'NotFoundPage',
  components: { ErrorPage },
};
</script>

<style lang="scss" scoped>
.error-code {
  @include font-size(36, 150, 500);
  text-align: center;
  color: #000;
}

.error-text {
  @include font-size(18, 150);
  text-align: center;
  color: #000;
}
</style>
