<script>
export default {
  name: 'InformationMixins',

  data() {
    return {
      mock: {
        articles: [
          {
            title: 'Title lipum lurem',
            subtitle: 'Subtitle Lipem lurem',
            description: 'Description ipsum lurem',
            priceValue: 'string',
            priceCurrency: 'string',
            ratingValue: 'string',
            ratingCount: 0,
            resource: 'string',
            thumbnail: 'string',
            callToActionText: 'string',
            tags: ['string'],
          },
        ],
      },
    };
  },

  computed: {
    relatedTopics() {
      if (this.hasInformationContent && this.articlesList.length > 0) {
        const tags = {};
        this.articlesList
          .map((article) => article.tags)
          .forEach((tagsInArticle) => {
            tagsInArticle.forEach((tag) => (tags[tag] = true));
          });
        return Object.keys(tags);
      }
      return [];
    },

    hasInformationContent() {
      return this.$store.getters[`information/hasInformationModuleContent`];
    },

    informationHookContent() {
      return this.$store.state['information'].informationModuleContent;
    },

    articlesState() {
      return this.$store.state['information'].articles;
    },

    hasInformationStagesError() {
      return this.$store.getters[`information/hasInformationStagesError`];
    },

    articlesCategoriesError() {
      if (!!this.articlesState) return this.articlesState.error;
      else return null;
    },

    articlesList() {
      return this.articlesState.value;
    },

    informationCategoriesError() {
      if (!this.hasInformationContent) return this.informationHookContent.error;
      else return null;
    },

    isLoadingContent() {
      return this.isLoadingInformationHookContent || this.isLoadingArticles;
    },

    isLoadingInformationHookContent() {
      return (
        (!!this.informationHookContent &&
          this.informationHookContent.isLoading) ||
        this.informationCategoriesError !== null
      );
    },

    isLoadingArticles() {
      return this.articlesState.isLoading;
    },

    hasCitySearch() {
      return this.$store.getters[`information/hasCitySearch`];
    },

    citySearch() {
      return this.$store.state['information'].citySearch;
    },

    reverseGeocode() {
      return this.$store.state['information'].reverseGeocode;
    },
  },

  methods: {
    loadInformationContent(id) {
      this.$store.dispatch(`information/loadInformationModuleItems`, id);
    },

    loadArticles(query) {
      return this.$store.dispatch('information/loadArticles', query);
    },

    performCitySearch(location) {
      this.$store.dispatch('information/performCitySearch', location);
    },

    performReverseGeocode({ lat, lng }) {
      this.$store.dispatch(`information/performReverseGeocode`, {
        lat: lat,
        lng: lng,
      });
    },

    clearError() {
      this.$store.dispatch('information/clearError');
    },
  },
};
</script>
