<template>
  <v-container fluid>
    <v-row no-gutters class="flex-column align-center">
      <v-col cols="auto">
        <v-img
          alt="errorPage"
          class="justify-center align-center"
          height="189"
          src="@/assets/error-page.svg"
          width="263"
        />
      </v-col>
      <v-col
        :class="{ 'error-message-mobile': !$vuetify.breakpoint.mdAndUp }"
        class="error-message"
        cols="auto"
      >
        <slot></slot>
      </v-col>
      <v-col cols="auto">
        <slot name="footer">
          <v-btn color="primary" depressed @click="goToHomePage">
            {{ buttonText ? buttonText : $t('exception.return_home') }}
          </v-btn>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConfigsMixins from '@/core/mixins/ConfigsMixins';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import InformationMixins from '@/modules/information/mixins/InformationMixins';

export default {
  name: 'ErrorPage',
  mixins: [ConfigsMixins, SelectedContextFlightMixins, InformationMixins],
  props: {
    link: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      preSelectedSearch: {
        city: null,
        longLat: null,
      },
      selectedItemValue: {
        text: '',
        longLat: null,
        city: null,
      },
      searchQuery: '',
    };
  },
  methods: {
    goToHomePage: function() {
      const link = {};
      const query = {};
      if (this.link) {
        link.path = this.link;
        this.$router.push(link);
      } else {
        if (
          this.$route.query.query &&
          typeof this.$route.query.query === 'string'
        ) {
          for (let [key, value] of new URLSearchParams(
            this.$route.query.query
          )) {
            query[key] = value;
          }
          link.query = query;
        }
        if (this.$route.query.link) {
          link.name = this.$route.query.link;
        }
        if (this.$route.query.path) {
          link.path = this.$route.query.path;
        }
        if (!Object.values(link).length) {
          link.path = '/';
        }
        this.$router.push(link);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  @include font-size(18, 25);
  text-align: center;
  color: var(--v-grey7-base);
  width: 500px;
  padding-top: 34px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    padding-top: 40px;
  }

  &-mobile {
    @include font-size(16, 150);
  }
}
</style>
